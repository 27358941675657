import React, { useCallback, useEffect, useState } from 'react';
import { Switch } from '@/components/ui/switch';
import Section from '../landing-page-components/section';
import { Label } from '@/components/ui/label';
import { MdOutlineCreditCardOff } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
import { productService } from '@/app/services/api/backend/product/productService';
import { useRouter } from 'next/navigation';
import { Tooltip } from '@mui/material';

interface Price {
  id: string;
  locations: number;
  unit_amount: number;
  currency: string;
}

interface Product {
  id: string;
  name: string;
  is_popular: boolean;
  description: string;
  priceMonthly: Price[];
  priceAnnual: Price[];
}

export const PricingSection: React.FC = () => {
  const router = useRouter();
  const texts = {
    pro: 'Digitise everything, even scanned PDFs. Run analyses and generate insights. Manage, analyze, and report all your geo-data in one place!',
    bus: 'Work as a team with shared workspace and an admin console to keep everything organized. Collaborate, manage, and interpret together!',
  };
  const [price, setPrice] = useState<{ [key: string]: number }>({});
  const [selectedPriceByPlan, setSelectedPriceByPlan] = useState<
    { planId: string; priceId: string }[]
  >([]);
  const [isMonthly, setIsMonthly] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);

  const handleToggle = () => {
    setIsMonthly(!isMonthly);
  };

  const handleSelection = (planId?: string) => {
    if (planId) {
      //now find priceID
      const priceId = selectedPriceByPlan.find((item) => item.planId === planId)
        ?.priceId;
      if (priceId) {
        sessionStorage.setItem('planId', planId);
        sessionStorage.setItem('priceId', priceId);
      }
    }
    router.push(`/api/auth/login`);
  };

  const setDefaultPrice = useCallback(() => {
    // console.log('calling setDefaultPrice');
    const newPlans: { planId: string; priceId: string }[] = [];
    products.forEach((product) => {
      const price = isMonthly
        ? product.priceMonthly[0]
        : product.priceAnnual[0];
      if (price) newPlans.push({ planId: product.id, priceId: price.id });
    });
    setSelectedPriceByPlan(newPlans);
  }, [products, isMonthly]);

  useEffect(() => {
    setDefaultPrice();
  }, [setDefaultPrice]);

  useEffect(() => {
    setPrice((prev) => {
      const newPrice = { ...prev };
      selectedPriceByPlan.forEach((item) => {
        const product = products.find((product) => product.id === item.planId);
        const price = isMonthly
          ? product?.priceMonthly.find((price) => price.id === item.priceId)
          : product?.priceAnnual.find((price) => price.id === item.priceId);
        // console.log('loop', { product, price });
        newPrice[item.planId] = (price?.unit_amount ?? 0) / 100;
      });
      // console.log({ newPrice, selectedPriceByPlan });
      return newPrice;
    });
  }, [products, isMonthly, selectedPriceByPlan]);

  const handlePlanChange = (plan: string, priceId: string) => {
    // console.log({ plan, priceId });
    setSelectedPriceByPlan((prev) => {
      const index = prev.findIndex((item) => item.planId === plan);
      if (index === -1) {
        return [...prev, { planId: plan, priceId }];
      }
      prev[index].priceId = priceId;
      return [...prev];
    });
  };

  useEffect(() => {
    const fetchProductsAndPrices = async () => {
      const response = await productService.getProducts();
      const stripeProducts = response.data;
      const productsMap: Product[] = stripeProducts.map((product: any) => {
        const priceMonthly = product.prices
          .filter((price: any) => price.recurring.interval === 'month')
          .map((price: any) => ({
            id: price.id,
            locations: price.metadata.locations,
            unit_amount: price.unit_amount,
            currency: price.currency,
          }))
          .sort((a: any, b: any) => a.locations - b.locations);
        const priceAnnual = product.prices
          .filter((price: any) => price.recurring.interval === 'year')
          .map((price: any) => ({
            id: price.id,
            locations: price.metadata.locations,
            unit_amount: price.unit_amount,
            currency: price.currency,
          }))
          .sort((a: any, b: any) => a.locations - b.locations);
        return {
          id: product.id,
          name: product.name,
          description: product.description,
          priceMonthly: priceMonthly,
          priceAnnual: priceAnnual,
          // @TODO: change back after 31 March
          // is_popular: product.metadata.is_popular ?? false, 
          is_popular: false,
        };
      });
      setProducts([...productsMap]);
    };
    fetchProductsAndPrices();
    // setDefaultPrice();
  }, []);
  return (
    <Section
      title=""
      subtitle="Choose the perfect plan for you"
      description="Interpet quicker, smarter, together within Geo-data Intelligence Platform"
    >
      <div className="flex justify-center mb-10">
        <span className="mr-2 font-semibold">Monthly</span>
        <label className="relative inline-flex items-center cursor-pointer">
          <Label>
            <Switch checked={!isMonthly} onCheckedChange={handleToggle} disabled style={{ color: '#011E41' }}/>
          </Label>
        </label>
        <span className="ml-2 font-semibold">Annual</span>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-white lg lg -translate-y-4 border-2 border-customBlue">
          {/* <div className="border-primary border-t-8"></div> */}
          <div className="text-center bg-customBlue text-white">
                Recommended
              </div>
          <h2 className="mt-0 p-4 border border-gray-300 bg-white text-left font-semNibold text-xl">
            Free
          </h2>
          <div className="mt-4">
            <span className="text-3xl font-bold p-4 pr-1">$0</span>
            <span className="text-base font-light">/mo</span>
          </div>
          <p className="text-gray-500 mt-1 flex items-center p-4">
            <MdOutlineCreditCardOff className="mr-2" /> No credit card required
          </p>
          <div className="bg-gray-100 p-4 lg mt-4">
            <p className="font-semibold text-gray-600">
              Single-use welcome locations:
            </p>
            <p className="text-gray-900 mt-2 w-full bg-white p-2 lg">
              250 Locations <span style={{ fontSize: '0.7em', color: 'green', fontWeight: 'bold' }}>(Available until March 31, 2025)</span>
            </p>
          </div>
          <div className="p-4">
            <button
              onClick={() => handleSelection('free')}
              className="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-3 px-6 rounded-lg w-full mt-6"
            >
              Start for free
            </button>
          </div>
          <div className="mt-4 p-4">
            <p className="font-semibold text-lg mb-2">Free includes:</p>
            <p className="text-gray-700">
              Add your borehole logs, edit on the spot, and view your data
              instantly on a map. Easy, quick, and ready to go!
            </p>
          </div>
        </div>
        {products.map((plan, index) => (
          <div
            key={index}
            className={`bg-white lg lg ${
              plan.is_popular ? '-translate-y-4 border-2 border-customBlue' : ''
            }`}
          >
            {plan.is_popular ? (
              <div className="text-center bg-customBlue text-white">
                Recommended
              </div>
            ) : (
              <div className="border-primary border-t-8"></div>
            )}
            <h2 className="mt-0 p-4 border border-gray-300 bg-white text-left font-semNibold text-xl">
              {plan.name}
            </h2>
            <div className="mt-4">
              <span className="text-3xl font-bold p-4 pr-1">
                ${price[plan.id]}
              </span>
              <span className="text-base font-light">/mo</span>
            </div>
            <p className="text-gray-500 mt-1 flex items-center p-4">
              All locations stored are provided upfront
            </p>
            <div className="bg-gray-100 p-4 lg mt-4">
              <p className="font-semibold text-gray-600">Locations/year:</p>
              {/*  */}
              <select
                disabled
                onChange={(event) =>
                  handlePlanChange(plan.id, event.target.value)
                }
                className="mt-2 w-full p-2 bg-white lg text-gray-900 focus:ring-2 focus:ring-blue-500"
              >
                {(isMonthly ? plan.priceMonthly : plan.priceAnnual).map(
                  (price, idx) => (
                    <option value={price.id} key={price.id}>
                      {price.locations} Locations
                    </option>
                  ),
                )}
              </select>
            </div>
            <div className="p-4">
              {plan.id != '' && (
                <Tooltip style={{ zIndex: '1000' }} title="Coming soon!">
                <button
                  disabled
                  onClick={() => handleSelection(plan.id)}
                  className="bg-customBlue hover:bg-customBlue text-white font-bold py-3 px-6 rounded-lg w-full mt-6"
                >
                  Get Started
                </button>
                </Tooltip>
              )}
              {/* {plan.name == 'Free' && (
                  <button className="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-3 px-6 rounded-lg w-full mt-6">
                    Start for free
                  </button>
                )} */}
            </div>
            <div className="mt-4 p-4">
              <p className="font-semibold text-lg mb-2">
                {plan.name == 'Professional'
                  ? 'Everything in Free, plus'
                  : 'Everything in Professional, plus'}
                :
              </p>
              <p className="text-gray-700">
                {plan.name == 'Professional' ? texts.pro : texts.bus}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};

const Pricing: React.FC = () => {
  const compare = [
    {
      title: 'Collaboration',
      items: [
        {
          title: 'Licenses',
          free: '1',
          professional: '1',
          business: 'Up to 50',
          enterprise: 'Unlimited',
        },
        {
          title: 'License Period',
          free: 'Always Free',
          professional: '12 months',
          business: '12 months',
          enterprise: 'Unlimited',
        },
        {
          title: 'Files History',
          free: '90-day access',
          professional: 'y',
          business: 'y',
          enterprise: 'y',
        },
        {
          title: 'Admin Console',
          free: '-',
          professional: 'y',
          business: 'y',
          enterprise: 'y',
        },
        {
          title: 'Platform',
          free: 'Cloud',
          professional: 'Cloud',
          business: 'Cloud',
          enterprise: 'Cloud - On Premises',
        },
      ],
    },
    {
      title: 'Data Digitalization',
      items: [
        {
          title: 'Licenses',
          free: '1',
          professional: '1',
          business: 'Up to 50',
          enterprise: 'Unlimited',
        },
        {
          title: 'License Period',
          free: 'Always Free',
          professional: '12 months',
          business: '12 months',
          enterprise: 'Unlimited',
        },
        {
          title: 'Files History',
          free: '90-day access',
          professional: 'y',
          business: 'y',
          enterprise: 'y',
        },
        {
          title: 'Admin Console',
          free: '-',
          professional: 'y',
          business: 'y',
          enterprise: 'y',
        },
        {
          title: 'Platform',
          free: 'Cloud',
          professional: 'Cloud',
          business: 'Cloud',
          enterprise: 'Cloud - On Premises',
        },
      ],
    },
  ];
  return (
    <>
      <PricingSection />
      <div className="max-w-6xl mx-auto p-4">
        <h2 className="text-2xl font-bold text-center mb-6">
          Compare all plans
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-collapse">
            <thead className="bg-white">
              <tr>
                <th className="text-left p-4"></th>
                <th className="text-center p-4 border-x">Free</th>
                <th className="text-center p-4 border-x">Professional</th>
                <th className="text-center p-4 border-x">Business</th>
                <th className="text-center p-4">Enterprise</th>
              </tr>
              <tr>
                <th className="text-left p-4"></th>
                <th className="text-center p-4 border-x">
                  <button className="bg-yellow-400 text-black font-bold py-2 px-4 rounded-lg">
                    Start for free
                  </button>
                </th>
                <th className="text-center p-4">
                  <button className="bg-teal-400 text-white font-bold py-2 px-4 rounded-lg">
                    Get Started
                  </button>
                </th>
                <th className="text-center p-4 border-x">
                  <button className="bg-teal-400 text-white font-bold py-2 px-4 rounded-lg">
                    Get Started
                  </button>
                </th>
                <th className="text-center p-4">
                  <button className="border border-black text-black font-bold py-2 px-4 rounded-lg">
                    Contact Sales
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {compare.map((section, index) => (
                <React.Fragment key={index}>
                  <tr className="bg-gray-200 border-r">
                    <td className="p-4 font-bold">{section.title}</td>
                    <td colSpan={4}></td>
                  </tr>
                  {section.items.map((item, idx) => (
                    <tr key={idx} className="odd:bg-white even:bg-gray-100">
                      <td className="p-4 border-r">{item.title}</td>
                      <td className="text-center p-4">
                        {item.free == 'y' ? <FaCheck /> : item.free}
                      </td>
                      <td className="text-center p-4">
                        {item.professional == 'y' ? (
                          <FaCheck
                            style={{ margin: '0 auto', color: '#61ADB9' }}
                          />
                        ) : (
                          item.professional
                        )}
                      </td>
                      <td className="text-center items-center p-4">
                        {item.business == 'y' ? (
                          <FaCheck
                            style={{ margin: '0 auto', color: '#61ADB9' }}
                          />
                        ) : (
                          item.business
                        )}
                      </td>
                      <td className="text-center items-center p-4">
                        {item.enterprise == 'y' ? (
                          <FaCheck
                            style={{ margin: '0 auto', color: '#61ADB9' }}
                          />
                        ) : (
                          item.enterprise
                        )}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
              <tr className="bg-gray-200 border-r">
                <td className="p-4 font-bold">Collaboration</td>
                <td colSpan={4}></td>
              </tr>
              <tr className="odd:bg-white even:bg-gray-100">
                <td className="p-4 border-r">Licenses</td>
                <td className="text-center p-4">1</td>
                <td className="text-center p-4">1</td>
                <td className="text-center p-4">Up to 50</td>
                <td className="text-center p-4">Unlimited</td>
              </tr>
              <tr className="odd:bg-white even:bg-gray-100">
                <td className="p-4 border-r">License Period</td>
                <td className="text-center p-4">Always Free</td>
                <td className="text-center p-4">12 months</td>
                <td className="text-center p-4">12 months</td>
                <td className="text-center p-4">Unlimited</td>
              </tr>
              <tr className="odd:bg-white even:bg-gray-100">
                <td className="p-4 border-r">Files History</td>
                <td className="text-center p-4">90-day access</td>
                <td className="text-center p-4">y</td>
                <td className="text-center p-4">y</td>
                <td className="text-center p-4">y</td>
              </tr>
              <tr className="odd:bg-white even:bg-gray-100">
                <td className="p-4 border-r">Admin Console</td>
                <td className="text-center p-4">-</td>
                <td className="text-center p-4">y</td>
                <td className="text-center p-4">y</td>
                <td className="text-center p-4">y</td>
              </tr>
              <tr className="odd:bg-white even:bg-gray-100">
                <td className="p-4 border-r">Platform</td>
                <td className="text-center p-4">Cloud</td>
                <td className="text-center p-4">Cloud</td>
                <td className="text-center p-4">Cloud</td>
                <td className="text-center p-4">Cloud - On Premises</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Pricing;
