"use client";

import { motion } from "framer-motion";

import { Icons } from "@/components/icons";
import HeroVideoDialog from "@/components/magicui/hero-video";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import Link from "next/link";
import dashboardImg from '@/assets/dashboard.png';
import VideoComponent from "./video";

const ease = [0.16, 1, 0.3, 1];

function HeroTitles() {
  return (
    <div className="flex w-full max-w-3xl flex-col space-y-4 overflow-hidden pt-8"> {/* Added 'pl-2' for alignment */}
      <motion.h1
        className="text-left font-medium text-foreground sm:text-2xl md:text-6xl"
        initial={{ filter: "blur(10px)", opacity: 0, y: 50 }}
        animate={{ filter: "blur(0px)", opacity: 1, y: 0 }}
        transition={{
          duration: 1,
          ease,
          staggerChildren: 0.2,
        }}
        style={{ maxWidth: '1000px' }}
      >
        {["Transform", "Your", "Geo-Data", "Into", "Valuable", "Insights"].map((text, index) => (
          <motion.span
            key={index}
            className="inline-block md:px-0"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.8,
              delay: index * 0.2,
              ease,
            }}
          >
            {text}
            {index < 5 && <>&nbsp;</>}
          </motion.span>
        ))}
      </motion.h1>
      <motion.p
        className="max-w-xl text-left text-muted-foreground sm:text-xl sm:leading-9"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.6,
          duration: 0.8,
          ease,
        }}
      >
        Streamline geotechnical projects with advanced data tools for efficient, intelligent workflows.
      </motion.p>
    </div>
  );
}

function HeroCTA() {
  return (
    <div>
      <motion.div
        className="flex w-full max-w-2xl flex-col sm:mt-4 sm:flex-row sm:space-x-4 sm:space-y-0"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.8, duration: 0.8, ease }}
      >
        <Link
          href="/api/auth/login"
          className={cn(
            buttonVariants({ variant: "default" }),
            "w-full sm:w-auto flex gap-2"
          )}
        >
          <Icons.getStarted className="w-auto h-[30px]" />
          Get started for free
        </Link>
        <a 
          className={buttonVariants({ variant: "outline" })} 
          href="https://outlook.office365.com/owa/calendar/FugroGeodataIntelligencePlatform@fugro.onmicrosoft.com/bookings/" 
          target="_blank">
            Get a demo
          </a>
      </motion.div>
      <motion.p
        className="text-xs text-muted-foreground flex items-center mb-1 w-full max-w-3xl sm:mt-5 sm:flex-row sm:space-x-4 sm:space-y-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.0, duration: 0.8 }}
      >
        <Icons.creditcard/>
        No credit card required.
      </motion.p>
    </div>
  );
}

function HeroImage() {
  return (
    <motion.div
      className="relative mx-auto flex w-full items-center justify-center"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 1.2, duration: 1, ease }}
    >
      {/* <HeroVideoDialog
        animationStyle="from-center"
        videoSrc="https://www.youtube.com/embed/qh3NGpYRG3I?si=4rb-zSdDkVK9qxxb"
        thumbnailSrc={dashboardImg.src}
        thumbnailAlt="Hero Video"
        className="border rounded-lg shadow-lg max-w-screen-lg mt-16 p-[10%]"
      /> */}
      <VideoComponent/>
    </motion.div>
  );
}

export default function Hero2() {
  return (
    <section id="hero">
      <div className="bg-heroColor bg-opacity-50 relative w-full items-center justify-start px-4 pt-32 sm:px-6 sm:pt-32 md:pt-16 lg:px-8">
      <div className="grid grid-cols-2 max-w-7xl mx-auto">
        <div className="text-left">
        <HeroTitles />
        <HeroCTA />
        </div>
        <div>
        <HeroImage />
        </div>
        </div>
        <div className="pointer-events-none absolute inset-x-0 -bottom-10 h-1/3 bg-gradient-to-t from-background via-background to-transparent lg:h-1/4"></div> 
      </div>
    </section>
  );
}
